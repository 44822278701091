var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxType.label) } },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: _vm.formRules.taxType.decorator,
                  expression: "formRules.taxType.decorator"
                }
              ],
              attrs: {
                name: _vm.formRules.taxType.name,
                placeholder: _vm.$t(_vm.formRules.taxType.placeholder),
                "option-filter-prop": "children",
                "filter-option": _vm.useLocalFilter,
                allowClear: true,
                loading: _vm.loading.taxType,
                disabled: _vm.disabled.taxType
              },
              on: { change: _vm.onChangeTaxType }
            },
            _vm._l(_vm.optTaxType, function(data) {
              return _c(
                "a-select-option",
                { key: data.key, attrs: { value: data.value } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" " + _vm._s(data.label) + " ")
                      ]),
                      _vm._v(" " + _vm._s(data.label) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxCode.label) } },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: _vm.formRules.taxCode.decorator,
                  expression: "formRules.taxCode.decorator"
                }
              ],
              attrs: {
                name: _vm.formRules.taxCode.name,
                placeholder: _vm.$t(_vm.formRules.taxCode.placeholder),
                showSearch: true,
                "filter-option": false,
                allowClear: true,
                loading: _vm.loading.taxCode,
                disabled: _vm.disabled.taxCode
              },
              on: { search: _vm.onSearchTaxCode, change: _vm.onChangeTaxCode }
            },
            _vm._l(_vm.optTaxCode, function(data) {
              return _c(
                "a-select-option",
                { key: data.key, attrs: { value: data.value } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" " + _vm._s(data.label) + " ")
                      ]),
                      _vm._v(" " + _vm._s(data.label) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceDate.label) } },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxInvoiceDate.decorator,
                expression: "formRules.taxInvoiceDate.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              placeholder: _vm.$t(_vm.formRules.taxInvoiceDate.placeholder),
              format: _vm.DEFAULT_DATE_FORMAT,
              disabled: _vm.disabled.taxInvoiceDate
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceNumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxInvoiceNumber.decorator,
                expression: "formRules.taxInvoiceNumber.decorator"
              }
            ],
            attrs: {
              name: _vm.formRules.taxInvoiceNumber.name,
              placeholder: _vm.$t(_vm.formRules.taxInvoiceNumber.placeholder),
              autocomplete: "off",
              disabled: _vm.disabled.taxInvoiceNumber
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxRegistrationNumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxRegistrationNumber.decorator,
                expression: "formRules.taxRegistrationNumber.decorator"
              }
            ],
            attrs: {
              disabled: _vm.disabled.taxRegistrationNumber,
              name: _vm.formRules.taxRegistrationNumber.name,
              placeholder: _vm.$t(
                _vm.formRules.taxRegistrationNumber.placeholder
              ),
              autocomplete: "off"
            },
            on: {
              blur: function(e) {
                return _vm.onBlurTaxRegNumb(e.currentTarget.value)
              },
              focus: function(e) {
                return _vm.onFocusTaxRegNumb(e.currentTarget.value)
              }
            }
          }),
          _c("ul", { attrs: { slot: "help" }, slot: "help" }, [
            _c("li", [_vm._v(_vm._s(_vm.$t("lbl_password_rule_2")))]),
            _c("li", [_vm._v("15 character")])
          ])
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxRegistrationName.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxRegistrationName.decorator,
                expression: "formRules.taxRegistrationName.decorator"
              }
            ],
            attrs: {
              name: _vm.formRules.taxRegistrationName.name,
              placeholder: _vm.$t(
                _vm.formRules.taxRegistrationName.placeholder
              ),
              autocomplete: "off",
              disabled: _vm.disabled.taxRegistrationName
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }