




































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import {
  useFindMasterTaxType,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
  useTax,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataTax } from "@/models/interface/settings.interface";
import {
  checkTaxNumber,
  formatTaxNumber,
  formatTaxNumbertoNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapMutations } from "vuex";

const Props = Vue.extend({
  props: ["disabled"],
});

@Component({
  inject: {
    formTable: {
      from: "formTable",
    },
  },
  methods: {
    ...mapMutations({
      setTaxRate: "aPPrepaymentStore/setTaxRate",
    }),
    ...mapActions({
      doCalculation: "aPPrepaymentStore/doCalculation",
    }),
  },
})
export default class TableTaxDetailsPrepayment extends Props {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useLocalFilter = useLocalFilter;

  formTable!: WrappedFormUtils;
  setTaxRate!: (payload: number) => void;
  doCalculation!: (payload: {
    amount: number;
    taxType: TAX_CALCULATION;
  }) => void;

  optTaxType: Array<Option> = [];
  optTaxCode: Array<Option<DataTax>> = [];

  loading = {
    taxType: false,
    taxCode: false,
  };

  formRules = {
    taxType: {
      label: "lbl_tax_type",
      name: "taxType",
      placeholder: "lbl_tax_type",
      decorator: [
        "taxType",
        {
          initialValue: TAX_CALCULATION.EXCLUSIVE,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxCode: {
      label: "lbl_tax_code",
      name: "taxCode",
      placeholder: "lbl_tax_code",
      decorator: [
        "taxCode",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxRegistrationNumber: {
      label: "lbl_tax_registration_number",
      name: "taxRegistrationNumber",
      placeholder: "lbl_tax_registration_number",
      decorator: [
        "taxRegistrationNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
            {
              validator: checkTaxNumber,
            },
          ],
        },
      ],
    },
    taxRegistrationName: {
      label: "lbl_tax_registration_name",
      name: "taxRegistrationName",
      placeholder: "lbl_tax_registration_name",
      decorator: ["taxRegistrationName"],
    },
    taxInvoiceDate: {
      label: "lbl_tax_invoice_date",
      name: "taxInvoiceDate",
      placeholder: "lbl_tax_invoice_date",
      decorator: [
        "taxInvoiceDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxInvoiceNumber: {
      label: "lbl_tax_invoice_number",
      name: "taxInvoiceNumber",
      placeholder: "lbl_tax_invoice_number",
      decorator: ["taxInvoiceNumber"],
    },
  };

  created(): void {
    this.fetchTaxCode = debounceProcess(this.fetchTaxCode);

    this.fetchTaxType();
    this.fetchTaxCode(new RequestQueryParams());
  }

  onBlurTaxRegNumb(val: string): void {
    this.formTable.setFieldsValue({
      taxRegistrationNumber: formatTaxNumber(val),
    });
  }

  onFocusTaxRegNumb(val: string): void {
    this.formTable.setFieldsValue({
      taxRegistrationNumber: formatTaxNumbertoNumber(val),
    });
  }

  fetchTaxType(): void {
    this.loading.taxType = true;
    useFindMasterTaxType()
      .then(response => {
        const filtered = response.filter(e => e.value.toUpperCase() !== "NONE");
        this.optTaxType = useMapMasterTypeToOptionAlt(filtered);
      })
      .finally(() => {
        this.loading.taxType = false;
      });
  }

  fetchTaxCode(params: RequestQueryParamsModel): void {
    const { findAll } = useTax();
    const builder = new SearchBuilder();

    // find only VAT_IN
    const q: Array<string> = [
      builder.push(["taxType", "VAT_IN"], { like: "both" }).build(),
    ];

    const copy = { ...params };
    if (copy.search) {
      q.unshift(copy.search);
    }

    params.search = q.join(builder.AND);

    this.loading.taxCode = true;
    findAll(params)
      .then(response => {
        this.optTaxCode = response.data.map(item => ({
          key: item.id,
          label: item.code || "-",
          value: item.code || "",
          meta: item,
        }));
      })
      .finally(() => {
        this.loading.taxCode = false;
      });
  }

  onSearchTaxCode(search = ""): void {
    const { filterBy } = useTax();
    const params = new RequestQueryParams();
    params.search = filterBy({ code: search });

    this.fetchTaxCode(params);
  }

  onChangeTaxCode(e: string | null): void {
    this.formTable.setFieldsValue({
      taxCode: e,
    });

    // side effect !!
    // set tax rate into store whenever tax code is changed
    const opt = this.optTaxCode.find(item => item.value === e);
    if (opt && opt.meta) {
      this.setTaxRate(opt.meta.rate);
    }

    const { amount, taxType } = this.formTable.getFieldsValue();
    this.doCalculation({ amount, taxType });
  }

  onChangeTaxType(e: string | null): void {
    this.formTable.setFieldsValue({
      taxType: e,
    });

    const { amount, taxType } = this.formTable.getFieldsValue();
    this.doCalculation({ amount, taxType });
  }
}
